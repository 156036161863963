import StylableHorizontalMenu_ScrollFlyoutComponent from '../components/StylableHorizontalMenu/viewer/skinComps/ScrollFlyout/ScrollFlyout.skin';
import StylableHorizontalMenu_ScrollFlyoutController from '../components/StylableHorizontalMenu/viewer/StylableHorizontalMenu.controller';


const StylableHorizontalMenu_ScrollFlyout = {
  component: StylableHorizontalMenu_ScrollFlyoutComponent,
  controller: StylableHorizontalMenu_ScrollFlyoutController
};


export const components = {
  ['StylableHorizontalMenu_ScrollFlyout']: StylableHorizontalMenu_ScrollFlyout
};

