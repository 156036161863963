import cn from 'clsx';
import { IMenuItemSDKAction } from '@wix/editor-elements-corvid-utils';
import React, { useState, useEffect, useRef } from 'react';
import { keyCodes } from '@wix/editor-elements-common-utils';
import Link from '@wix/thunderbolt-elements/components/Link';
import { testIds } from '../../testIds';
import type {
  IMenuItemLabelProps,
  MenuItemProps,
} from '../../../StylableHorizontalMenu.types';
import { getLabelClasses } from './styles/getLabelClasses';

const createEventListeners = (
  setIsHovered: (val: React.SetStateAction<boolean>) => void,
) => {
  const showSubmenu = () => setIsHovered(true);
  const hideSubmenu = () => setIsHovered(false);

  return {
    onMouseEnter: showSubmenu,
    onMouseLeave: hideSubmenu,
    onFocus: showSubmenu,
    onBlur: (e: React.FocusEvent) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        hideSubmenu();
      }
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      const menuItemLabel = e.currentTarget.firstChild as HTMLElement;
      if (menuItemLabel !== e.target) {
        return;
      }

      switch (e.keyCode) {
        case keyCodes.arrowLeft:
        case keyCodes.arrowRight:
          const siblingNavigateTo =
            e.currentTarget[
              e.keyCode === keyCodes.arrowLeft
                ? 'previousSibling'
                : 'nextSibling'
            ];
          (siblingNavigateTo?.firstChild as HTMLElement)?.focus();
          break;
        default:
          break;
      }
    },
  };
};

const createSDKAction = (
  item: MenuItemProps,
  selected: boolean,
  sdkAction?: IMenuItemSDKAction,
) =>
  sdkAction &&
  ((e: React.MouseEvent) => {
    sdkAction?.(e, {
      ...item,
      selected,
    });
  });

export const MenuItemLabel: React.FC<IMenuItemLabelProps> = ({
  item,
  className,
  withSubItemsClassname,
  isCurrentItem: isCurrentPage,
  depth,
  isStretched,
  hasColumnSubSubs,
  positionUpdaters: positionUpdatersMap,
  positionBoxRef,
  children,
  onItemClick,
  onItemDblClick,
  onItemMouseIn,
  onItemMouseOut,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const labelRef = useRef<HTMLAnchorElement | HTMLDivElement | null>(null);

  const { label, link, forceHovered = false } = item;
  const eventListeners = createEventListeners(setIsHovered);

  const isHeading = hasColumnSubSubs && depth === 1;
  const classes = getLabelClasses({
    depth,
    isHovered: isHeading ? false : isHovered,
    isCurrentPage,
    className,
  });

  const positionUpdaters = positionUpdatersMap[depth];
  useEffect(() => {
    if (
      !isHovered ||
      !labelRef.current ||
      !positionBoxRef.current ||
      !positionUpdaters
    ) {
      return;
    }

    const { onEnter, onLeave } = positionUpdaters({
      label: labelRef.current,
      positionBox: positionBoxRef.current,
      isStretched,
    });
    onEnter();
    setIsShown(true);
    return () => {
      onLeave();
      setIsShown(false);
    };
  }, [isHovered, isStretched, positionBoxRef, positionUpdaters, item]);

  useEffect(() => {
    setIsHovered(forceHovered);
  }, [forceHovered]);

  return (
    <li
      className={cn(classes.itemWrapper, withSubItemsClassname)}
      data-testid={testIds.menuItem(depth)}
      data-item-depth={depth}
      data-is-current={isCurrentPage}
      aria-current={isCurrentPage}
      {...(isHovered && {
        'data-hovered': true,
      })}
      {...(isShown && {
        'data-shown': true,
      })}
      {...eventListeners}
    >
      <Link
        {...link}
        className={classes.root}
        ref={labelRef}
        onClick={createSDKAction(item, isCurrentPage, onItemClick)}
        onMouseEnter={createSDKAction(item, isCurrentPage, onItemMouseIn)}
        onMouseLeave={createSDKAction(item, isCurrentPage, onItemMouseOut)}
        onDoubleClick={createSDKAction(item, isCurrentPage, onItemDblClick)}
        tabIndex={0}
      >
        <div className={classes.container}>
          <span className={classes.label}>{label}</span>
        </div>
      </Link>
      {children}
    </li>
  );
};
